import {
  IconMenu,
  MenuItemList,
  MenuItemNode,
} from "@react-admin/ra-navigation";

import PeopleIcon from "@mui/icons-material/People";
import { CardContent, Typography } from "@mui/material";
import TagIcon from "@mui/icons-material/Tag";
import EventIcon from "@mui/icons-material/Event";

const MyMenu = () => (
  <IconMenu>
    <IconMenu.Item
      name="users.management"
      label="Community"
      icon={<PeopleIcon />}
    >
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Users Management
        </Typography>
        <MenuItemList>
          <MenuItemNode name="users.list" to={"/user"} label="Users" />
          <MenuItemNode name="school.list" to={"/school"} label="School" />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
    <IconMenu.Item
      name="categories.management"
      label="Categories"
      icon={<TagIcon />}
    >
      <CardContent>
        <Typography variant="h4" gutterBottom>
          Categories Management
        </Typography>
        <MenuItemList>
          <MenuItemNode
            name="mainCategory.list"
            to={"/mainCategory"}
            label="Main Category"
          />
          <MenuItemNode
            name="category.list"
            to={"/category"}
            label="Category"
          />
          <MenuItemNode name="mood.list" to={"/mood"} label="Moods" />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
    <IconMenu.Item
      name="event.list"
      label="Events"
      to={"/event"}
      icon={<EventIcon />}
    />
  </IconMenu>
);

export default MyMenu;

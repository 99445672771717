import React from "react";
import {
  TextInput,
  required,
  Edit,
  TabbedForm,
  FormTab,
  Datagrid,
  ReferenceManyField,
  TextField,
  ImageInput,
  ImageField,
} from "react-admin";

const MainCategoryEdit = () => {
  return (
    <Edit>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="id" disabled />
          <TextInput source="name" validate={[required()]} />
          <ImageInput source="image" accept={"image/*"} validate={[required()]}>
            <ImageField source="src" />
          </ImageInput>
        </FormTab>
        <FormTab label="categories">
          <ReferenceManyField reference="category" target="mainCategoryId">
            <Datagrid sx={{ width: 800 }}>
              <TextField source="name" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default MainCategoryEdit;

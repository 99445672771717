import { ReferenceManyToManyInput } from "@react-admin/ra-relationships";
import React from "react";
import {
  TextInput,
  required,
  Edit,
  TabbedForm,
  FormTab,
  ReferenceInput,
  AutocompleteInput,
  SelectArrayInput,
  ImageInput,
  ImageField,
  Toolbar,
  SaveButton,
  ToolbarProps,
} from "react-admin";

const UserEditToolbar = (props: ToolbarProps) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
);

const UserEdit = () => {
  return (
    <Edit actions={false}>
      <TabbedForm toolbar={<UserEditToolbar />}>
        <FormTab label="summary">
          <TextInput source="id" disabled />
          <TextInput source="email" validate={[required()]} />
          <TextInput source="password" />
          <ImageInput source="image" accept={"image/*"} validate={[required()]}>
            <ImageField source="src" />
          </ImageInput>
          <ReferenceInput source="schoolId" reference="school">
            <AutocompleteInput
              optionText="name"
              filterToQuery={(value) => ({
                name: { contains: value },
              })}
            />
          </ReferenceInput>
          <ReferenceManyToManyInput
            source="id"
            reference="role"
            through="userRole"
            using="userId,roleId"
          >
            <SelectArrayInput label="Role" optionText="name" />
          </ReferenceManyToManyInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default UserEdit;

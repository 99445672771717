import { List, Datagrid, TextField } from "react-admin";

const MoodList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
);

export default MoodList;

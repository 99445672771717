// in src/MyLayout.js
import { AppLocationContext } from "@react-admin/ra-navigation";
import { Layout } from "@react-admin/ra-enterprise";

import Menu from "./Menu";

const MyLayout = (props: any) => (
  <AppLocationContext>
    <Layout {...props} menu={Menu} />
  </AppLocationContext>
);

export default MyLayout;

import React from "react";
import {
  TextInput,
  required,
  Edit,
  TabbedForm,
  FormTab,
  Datagrid,
  ReferenceManyField,
  TextField,
} from "react-admin";

const SchoolEdit = () => {
  return (
    <Edit>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="id" disabled />
          <TextInput source="name" validate={[required()]} />
          <TextInput source="emailExtension" validate={[required()]} />
        </FormTab>
        <FormTab label="users">
          <ReferenceManyField reference="user" target="schoolId">
            <Datagrid sx={{ width: 800 }}>
              <TextField source="id" />
              <TextField source="email" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default SchoolEdit;

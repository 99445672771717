import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { omit } from "lodash";
import { Identifier } from "react-admin";
import { onError } from "@apollo/client/link/error";
import { env } from "./env";
import { AzureAuthClient } from "./services/AuthClient";

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
const authLink = setContext(async (_, { headers }) => {
  const token = AzureAuthClient.getToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri: env.API_URL + "/graphql",
});

const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

const fields = {
  user: "id email imageId firstName lastName referredCodeId schoolId image { src }",
  school: "id name emailExtension",
  image: "id source ownerId",
  referralCode: "id code utilisationLeft ownerId",
  mainCategory: "id name image {id src}",
  category: "id name image {id src} emoji mainCategoryId",
  mood: "id name",
  categoryMood: "id categoryId moodId",
  userRole: "id userId roleId",
  role: "id name",
  event:
    "id name description startTime maxParticipants isCatchMe categoryId ownerId isCanceled",
};

export const dataProvider = {
  getList: (
    resource: string,
    {
      sort,
      pagination,
      filter,
    }: {
      sort: { field: string; order: string };
      pagination: { page: number; perPage: number };
      filter: any;
    },
  ) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    return client
      .query({
        query: gql`
            query ($take: Int, $skip: Int, $order_by: [${capitalizeFirstLetter(
              resource,
            )}OrderByWithRelationInput!], $where: ${capitalizeFirstLetter(
              resource,
            )}WhereInput!) {
                ${resource}s(take: $take, skip: $skip, orderBy: $order_by, where: $where) {
                    ${fields[resource as keyof typeof fields]}
                }
                ${resource}sCount(where: $where)
            }`,
        variables: {
          take: perPage,
          skip: (page - 1) * perPage,
          order_by: { [field]: order.toLowerCase() },
          where: filter || {},
        },
      })
      .then((result) => {
        if (result.errors?.length) throw new Error(result.errors[0].message);
        return {
          data: result.data[`${resource}s`],
          total: result.data[`${resource}sCount`],
        };
      });
  },
  getOne: (resource: string, params: { id: number }) => {
    return client
      .query({
        query: gql`
            query ($where: ${capitalizeFirstLetter(
              resource,
            )}WhereUniqueInput!) {
                ${resource}(where: $where) {
                    ${fields[resource as keyof typeof fields]}
                }
            }`,
        variables: {
          where: { id: params.id },
        },
      })
      .then((result) => ({ data: result.data[resource] }));
  },
  getMany: (resource: string, params: { ids: Identifier[] }) => {
    return client
      .query({
        query: gql`
            query ($where: ${capitalizeFirstLetter(resource)}WhereInput!) {
                ${resource}s(where: $where) {
                    ${fields[resource as keyof typeof fields]}
                }
            }`,
        variables: {
          where: {
            id: { in: params.ids.map((_) => _) },
          },
        },
      })
      .then((result) => ({ data: result.data[`${resource}s`] }));
  },
  getManyReference: (
    resource: string,
    {
      target,
      id,
      sort,
      pagination,
      filter,
    }: {
      target: string;
      id: Identifier;
      sort: { field: string; order: string };
      pagination: { page: number; perPage: number };
      filter: any;
    },
  ) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    return client
      .query({
        query: gql`
            query ($take: Int, $skip: Int, $order_by: [${capitalizeFirstLetter(
              resource,
            )}OrderByWithRelationInput!], $where: ${capitalizeFirstLetter(
              resource,
            )}WhereInput!) {
                ${resource}s(take: $take, skip: $skip, orderBy: $order_by, where: $where) {
                    ${fields[resource as keyof typeof fields]}
                }
                ${resource}sCount(where: $where)
            }`,
        variables: {
          take: perPage,
          skip: (page - 1) * perPage,
          order_by: { [field]: order.toLowerCase() },
          where: {
            [`${target}`]: { equals: id },
          },
        },
      })
      .then((result) => ({
        data: result.data[`${resource}s`],
        total: result.data[`${resource}sCount`],
      }));
  },
  create: async (resource: string, params: { data: any }) => {
    if ("image" in params.data) {
      const image = params.data.image?.rawFile as File;
      if (image) {
        const result = await uploadImage(image);
        params.data.imageId = result.id;
      }
      delete params.data.image;
    }
    return client
      .mutate({
        mutation: gql`
            mutation ($data: ${capitalizeFirstLetter(
              resource,
            )}UncheckedCreateInput!) {
              create${capitalizeFirstLetter(resource)}(data: $data) {
                    ${fields[resource as keyof typeof fields]}
                }
            }`,
        variables: {
          data: omit(params.data, ["__typename"]),
        },
      })
      .then((result) => ({
        data: result.data[`create${capitalizeFirstLetter(resource)}`],
      }));
  },
  update: async (resource: string, params: { data: any; id: Identifier }) => {
    if ("image" in params.data) {
      const image = params.data.image?.rawFile as File;
      if (image) {
        const result = await uploadImage(image);
        params.data.imageId = result.id;
      }
      if (!params.data.image) {
        params.data.imageId = null;
      }
      delete params.data.image;
    }
    delete params.data.id;
    Object.keys(params.data)
      .filter((_) => params.data[_])
      .forEach((key) => {
        params.data[key] = { set: params.data[key] };
      });
    return client
      .mutate({
        mutation: gql`
            mutation ($where: ${capitalizeFirstLetter(
              resource,
            )}WhereUniqueInput!, $data: ${capitalizeFirstLetter(
              resource,
            )}UncheckedUpdateInput!) {
                update${capitalizeFirstLetter(
                  resource,
                )}(where: $where, data: $data) {
                    ${fields[resource as keyof typeof fields]}
                }
            }`,
        variables: {
          where: { id: params.id },
          data: omit(params.data, ["__typename"]),
        },
      })
      .then((result) => ({
        data: result.data[`update${capitalizeFirstLetter(resource)}`],
      }));
  },
  updateMany: (resource: string, params: { ids: Identifier[]; data: any }) => {
    return client
      .mutate({
        mutation: gql`
            mutation ($where: ${capitalizeFirstLetter(
              resource,
            )}WhereInput!, $data: ${capitalizeFirstLetter(
              resource,
            )}UpdateManyMutationInput!) {
                ${resource}UpdateMany(where: $where, data: $data)
            }`,
        variables: {
          where: {
            id: { in: params.ids },
          },
          data: omit(params.data, ["__typename"]),
        },
      })
      .then((result) => ({
        data: params.ids,
      }));
  },
  delete: (resource: string, params: { id: Identifier }) => {
    return client
      .mutate({
        mutation: gql`
            mutation ($id: Int!) {
                ${resource}Delete(where: {id: $id}) {
                    ${fields[resource as keyof typeof fields]}
                }
            }`,
        variables: {
          id: params.id,
        },
      })
      .then((result) => ({
        data: result.data[`${resource}Delete`],
      }));
  },
  deleteMany: (resource: string, params: { ids: Identifier[] }) => {
    return client
      .mutate({
        mutation: gql`
            mutation ($where: ${capitalizeFirstLetter(resource)}WhereInput!) {
                ${resource}DeleteMany(where: $where)
            }`,
        variables: {
          where: {
            id: { in: params.ids.map((_) => _) },
          },
        },
      })
      .then((result) => ({
        data: params.ids,
      }));
  },
};

const uploadImage = async (file: File) => {
  const data = new FormData();
  data.append("file", file);
  const result = await fetch(env.API_URL + "/upload", {
    method: "POST",
    body: data,
  });
  const dataResult = await result.json();
  if (dataResult.error) {
    throw new Error(dataResult.error);
  }
  return dataResult;
};

import React from "react";
import { Resource } from "react-admin";
import { Admin, buildI18nProvider } from "@react-admin/ra-enterprise";
import englishMessages from "ra-language-english";
import { BrowserRouter } from "react-router-dom";
import { dataProvider as apolloProvider } from "./dataProvider";
import frenchMessages from "ra-language-french";
import MyLayout from "./components/Layout";
import {
  raAuditLogLanguageEnglish,
  raAuditLogLanguageFrench,
} from "@react-admin/ra-audit-log";
import authProvider from "./authProvider";
import UserEdit from "./screens/user/UserEdit";
import UserList from "./screens/user/UserList";

import SchoolEdit from "./screens/school/SchoolEdit";
import SchoolList from "./screens/school/SchoolList";
import SchoolCreate from "./screens/school/SchoolCreate";
import CategoryCreate from "./screens/category/CategoryCreate";
import CategoryEdit from "./screens/category/CategoryEdit";
import CategoryList from "./screens/category/CategoryList";
import MainCategoryCreate from "./screens/mainCategory/MainCategoryCreate";
import MainCategoryEdit from "./screens/mainCategory/MainCategoryEdit";
import MainCategoryList from "./screens/mainCategory/MainCategoryList";
import MoodCreate from "./screens/mood/MoodCreate";
import MoodEdit from "./screens/mood/MoodEdit";
import MoodList from "./screens/mood/MoodList";
import EventEdit from "./screens/event/EventEdit";
import EventList from "./screens/event/EventList";

const messages: any = {
  en: { ...englishMessages, ...raAuditLogLanguageEnglish },
  fr: { ...frenchMessages, ...raAuditLogLanguageFrench },
};

const i18nProvider = buildI18nProvider(messages, "fr");

const App = () => (
  <BrowserRouter>
    <Admin
      dataProvider={apolloProvider}
      i18nProvider={i18nProvider}
      authProvider={authProvider}
      locale="fr"
      layout={MyLayout}
    >
      <Resource name="user" list={UserList} edit={UserEdit} />
      <Resource
        name="school"
        list={SchoolList}
        edit={SchoolEdit}
        create={SchoolCreate}
      />
      <Resource
        name="mainCategory"
        list={MainCategoryList}
        edit={MainCategoryEdit}
        create={MainCategoryCreate}
      />
      <Resource
        name="category"
        list={CategoryList}
        edit={CategoryEdit}
        create={CategoryCreate}
      />
      <Resource
        name="mood"
        list={MoodList}
        edit={MoodEdit}
        create={MoodCreate}
      />
      <Resource name="event" list={EventList} edit={EventEdit} />
    </Admin>
  </BrowserRouter>
);

export default App;

import React from "react";
import {
  TextInput,
  required,
  TabbedForm,
  FormTab,
  Create,
  ImageInput,
  ImageField,
} from "react-admin";

const MainCategoryCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" validate={[required()]} />
          <ImageInput source="image" accept={"image/*"} validate={[required()]}>
            <ImageField source="source" />
          </ImageInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default MainCategoryCreate;

import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceField,
  DateField,
} from "react-admin";

const EventList = () => (
  <List>
    <Datagrid rowClick="edit" sort={{ field: "startTime", order: "desc" }}>
      <TextField source="name" />
      <BooleanField source="isCatchMe" />
      <DateField source="startTime" showTime />
      <ReferenceField source="ownerId" reference="user">
        <TextField source="email" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default EventList;

import React from "react";
import { TextInput, required, TabbedForm, FormTab, Create } from "react-admin";

const SchoolCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" validate={[required()]} />
          <TextInput source="emailExtension" validate={[required()]} />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default SchoolCreate;

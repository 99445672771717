import jwt_decode from "jwt-decode";
import { env } from "../env";

type JWT = {
  email: string;
  exp: number;
  iat: number;
  sub: string;
};

export class AzureAuthClient {
  static async login({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) {
    const result = await fetch(env.API_URL + "/auth/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: username,
        password,
      }),
    });
    const data = await result.json();
    localStorage.setItem("refresh_token", data.payload.refresh_token);
    localStorage.setItem("access_token", data.payload.access_token);
  }

  static isAuth() {
    const token = this.getToken();
    return !!token;
  }

  static getDecodedToken() {
    const token = this.getToken();
    if (!token) throw new Error("No token found");
    return AzureAuthClient.decodeToken(token);
  }

  static decodeToken(token: string) {
    return jwt_decode<JWT>(token);
  }

  static getToken() {
    if (env.TOKEN) return env.TOKEN;
    return localStorage.getItem("access_token");
  }

  static logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
  }
}

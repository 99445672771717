import { ReferenceManyToManyInput } from "@react-admin/ra-relationships";
import React from "react";
import {
  TextInput,
  required,
  TabbedForm,
  FormTab,
  Create,
  AutocompleteArrayInput,
} from "react-admin";

const MoodCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" validate={[required()]} />
          <ReferenceManyToManyInput
            source="id"
            reference="category"
            through="categoryMood"
            using="moodId,categoryId"
          >
            <AutocompleteArrayInput
              label="Category"
              optionText="name"
              filterToQuery={(value) => ({ name: { contains: value } })}
            />
          </ReferenceManyToManyInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default MoodCreate;

import { List, Datagrid, TextField, ReferenceField } from "react-admin";

const CategoryList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="emoji" />
      <ReferenceField source="mainCategoryId" reference="mainCategory">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default CategoryList;

import { AuthProvider } from "react-admin";
import { AzureAuthClient } from "./services/AuthClient";

const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    await AzureAuthClient.login({ username, password });
  },
  logout: () => {
    AzureAuthClient.logout();
    return Promise.resolve();
  },
  checkAuth: () => {
    console.log("checkAuth", AzureAuthClient.isAuth());
    if (!AzureAuthClient.isAuth()) {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkError: (error) => {
    if (error.message === "GqlAuthGuard" || error.message === "Unauthorized") {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
  getIdentity: () => {
    const decodedToken = AzureAuthClient.getDecodedToken();
    return Promise.resolve({
      id: decodedToken.sub,
      fullName: decodedToken.email,
    });
  },
};

export default authProvider;

import { List, Datagrid, TextField, ReferenceField } from "react-admin";

const UserList = () => (
  <List>
    <Datagrid rowClick="edit" bulkActionButtons={false}>
      <TextField source="email" />
      <ReferenceField source="schoolId" reference="school">
        <TextField source="name" />
      </ReferenceField>
    </Datagrid>
  </List>
);

export default UserList;

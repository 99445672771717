import { List, Datagrid, TextField } from "react-admin";

const SchoolList = () => (
  <List>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="emailExtension" />
    </Datagrid>
  </List>
);

export default SchoolList;

declare global {
  interface Window {
    env: {
      [key: string]: string;
    };
  }
}

const pEnv = window.env;

export const env = {
  API_URL: pEnv.REACT_APP_API_URL || process.env.REACT_APP_API_URL,
  TOKEN: pEnv.REACT_APP_TOKEN || process.env.REACT_APP_TOKEN,
};

import React from "react";
import { ReferenceManyToManyInput } from "@react-admin/ra-relationships";
import {
  TextInput,
  required,
  TabbedForm,
  FormTab,
  Create,
  ImageInput,
  ImageField,
  AutocompleteArrayInput,
  AutocompleteInput,
  ReferenceInput,
} from "react-admin";

const CategoryCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="name" validate={[required()]} />
          <TextInput source="emoji" validate={[required()]} />
          <ImageInput source="image" accept={"image/*"} validate={[required()]}>
            <ImageField source="src" />
          </ImageInput>
          <ReferenceManyToManyInput
            source="id"
            reference="mood"
            through="categoryMood"
            using="categoryId,moodId"
          >
            <AutocompleteArrayInput
              label="Moods"
              optionText="name"
              filterToQuery={(value) => ({ name: { contains: value } })}
            />
          </ReferenceManyToManyInput>
          <ReferenceInput source="mainCategoryId" reference="mainCategory">
            <AutocompleteInput
              optionText="name"
              validate={[required()]}
              filterToQuery={(value) => ({ name: { contains: value } })}
            />
          </ReferenceInput>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CategoryCreate;

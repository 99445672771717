import React from "react";
import {
  TextInput,
  Edit,
  TabbedForm,
  FormTab,
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  ArrayField,
  EmailField,
  Datagrid,
  DateTimeInput,
} from "react-admin";

const EventEdit = () => {
  return (
    <Edit>
      <TabbedForm>
        <FormTab label="summary">
          <TextInput source="id" disabled />
          <TextInput source="name" />
          <TextInput source="description" />
          <DateTimeInput source="startTime" />
          <NumberInput source="maxParticipants" />
          <BooleanInput source="isCatchMe" disabled />
          <ReferenceInput source="categoryId" reference="category">
            <AutocompleteInput
              optionText="name"
              filterToQuery={(value) => ({
                name: { contains: value },
              })}
            />
          </ReferenceInput>
          <ReferenceInput source="ownerId" reference="user" disabled>
            <AutocompleteInput
              disabled
              optionText="email"
              filterToQuery={(value) => ({
                name: { contains: value },
              })}
            />
          </ReferenceInput>
          <BooleanInput source="isCanceled" disabled />
        </FormTab>
        <FormTab label="participants">
          <ArrayField source="participants">
            <Datagrid>
              <EmailField source="email" />
            </Datagrid>
          </ArrayField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default EventEdit;
